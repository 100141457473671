
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readPredictionsByIds,
  readTotalPredictions,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
} from "@/store/model/actions";

import {
  readUserProfile,
  readHasAdminAccess,
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
} from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
  },
})
export default class ModelView extends Vue {
  public id: number = 1;
  public showMetric: boolean = false;
  public metricThreshold: number = 0.95;
  public dialogLabel: boolean = false;
  public dialogLabelContainer: boolean = false;
  public labelName: string = "";
  public labelExamples: string[] = [];
  public labelContainerName: string = "";
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeItemId: string = "";
  public activeLabelExample: string = "";
  public preview: boolean = false;
  public loading: boolean = false;
  public redoing: boolean = false;
  public drawer: boolean = true;

  public targetItems: IItemUpdate[] = [];

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
    "black",
    "white",
  ];

  get offsetMenu() {
    if (!this.showDrawer) {
      return 0;
    } else if (this.miniDrawer) {
      return "80";
    } else if (!this.miniDrawer) {
      return "300";
    }
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc
    if (this.model?.status === "ready") {
      if (this.model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  public capitalizeFirstLetter(inputString: string): string {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  get nextTraining() {
    return (this.model!.total_labeled % 10) * 10;
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */

  get isAdmin() {
    return readHasAdminAccess;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get totalPredictions() {
    return readTotalPredictions(this.$store);
  }

  get accuracy() {
    return readAccuracy(this.$store);
  }

  get metrics() {
    return readMetrics(this.$store);
  }

  get models() {
    return readModels(this.$store);
  }

  get recommendation() {
    return readRecommendation(this.$store);
  }
  get recommendationType() {
    return readRecommendationType(this.$store);
  }
  get target() {
    return readTarget(this.$store);
  }

  get item() {
    return this.items[0];
  }
  get items() {
    return readItems(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get valueDeterminate() {
    return 20;
  }

  public createLabel(id) {
    this.dialogLabel = true;
    this.activeLabelContainerId = id;
  }

  public createLabelContainer() {
    this.dialogLabelContainer = true;
  }
}
